.container {
  display: flex;
  margin: 20px;
}

.sidebar {
  flex: 1;
  max-width: 200px;
  overflow-y: auto;
  margin-right: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.sidebar li:hover {
  background-color: #f0f0f0;
}

.sidebar li.active {
  font-weight: bold;
  background-color: #e0e0e0;
}

.content {
  flex: 3;
  margin-left: 20px;
}

.highlight {
  flex: 1;
  max-width: 200px;
  margin-left: 20px;
}

.highlight ul {
  list-style-type: none;
  padding: 0;
}

.highlight li {
  cursor: pointer;
  padding: 10px;
}

.highlight li.active {
  font-weight: bold;
  background-color: #e0e0e0;
}

::-webkit-scrollbar {
  width: 8px; /* Changed width to 8px */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Documentation.css */
body {
  overflow-x: hidden;
}

#root {
  overflow-x: hidden;
}

.Box-root {
  overflow-x: hidden;
}