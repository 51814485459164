/* Color Variables */
:root {
  --primary-color: #000000;
  --link-color: #61dafb;
  --secondary-color: white;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  
}

.App-link {
  color: var(--link-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sectionStyle {
  background-color: var(--secondary-color);
  padding: '60px 0';
  min-height: '100vh';
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
}

.primary-button {
  background-color: transparent !important;
  color: var(--secondary-color) !important;
  border-radius: 0% !important;
  border-width: 2px !important;
  border-color: var(--secondary-color) !important;
  border-style: solid !important;
}

.secondary-button {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-radius: 0% !important;
  border-width: 2px !important;
  border-color: var(--primary-color) !important;
  border-style: solid !important;
}

.hero-text{
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color :#fff;
}

.section-container {
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
}

.footer-link {
  text-decoration: none;
  color: inherit;
  margin-bottom: 10px !important;
}


